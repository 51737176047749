/* import { navigate } from "gatsby-link"; */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components"
import { TextField, Button, Select, MenuItem, Card, CardContent } from "@material-ui/core"
import { camposDelSender, camposDelEncuestado } from "../../../../static/json/camposFormEncuesta";
import { navigate } from "gatsby";
// import { productos, equipo_preventa, tipo_de_encuesta } from "../../../../static/json/servicio"; Testing

const StyledTextField = styled(TextField)`
  ${props =>
        css`
      input {
        color: #000 !important;
      }
      div:after, div:before {
        border-bottom: none;
      }
      div {
        color: #000 !important;
        background-color: #ffffff;
        box-shadow: 2px 5px 14px 0 rgba(0,0,0,0.1);
        &:hover {
            background-color: #ffffff;
            box-shadow: 2px 5px 14px 0 rgba(0,0,0,0.1);
        }
      }
      label {
        color: #000000 !important;
        font-family: Raleway;
      }
      textarea {
        color: #000 !important;
      }
    `}
`

const StyledSelectField = styled(Select)`
  && {
    margin-bottom: 20px;
    color: #000 !important;
    background-color: #ffffff;
    box-shadow: 2px 5px 14px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #ffffff;
      box-shadow: 2px 5px 14px 0 rgba(0, 0, 0, 0.1);
    }

    label {
      color: #000000 !important;
      font-family: Raleway;
    }
  }
`;

const StyledDateField = styled(TextField)`
  && {
    margin-bottom: 10px;
    background-color: #ffffff;
    box-shadow: 2px 5px 14px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #ffffff;
      box-shadow: 2px 5px 14px 0 rgba(0, 0, 0, 0.1);
    }

    label {
      color: #000000;
      font-family: Raleway;
    }

    input {
        color: #000;
    }
  }
`;

const getFormattedToday = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; 
    let day = today.getDate();
  
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
  
    return `${year}-${month}-${day}`;
};

const URL = process.env.GATSBY_API_URL
//const URL = "http://localhost:6001"

const EncuestaFormulario = () => {
    /* const [messageLength, setMessageLength] = useState()
    const [submitStatus, setSubmitStatus] = useState("standby")
    const [submitClick, setSubmitClick] = useState(false)
    const [sendData, setSendData] = useState() */

    // data selects
    const [product, setProduct] = useState();
    const [presaleTeam, setPresaleTeam] = useState();
    const [surveyType, setSurveyType] = useState();

    const [enviarStatus, setEnviarStatus] = useState("Enviar");

    const { register, handleSubmit, setValue, errors, reset } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    })
    /* const onSubmit = data => {
        if(page) data.page = page;
        setSendData(data)
        setSubmitClick(true)
    } */

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(URL+'/product');
            const data = await response.json();
            setProduct(data);
          } catch (error) {
            // console.error('Error fetching data: Product', error);
          }
        };
    
        fetchData();
    }, []); 

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(URL+'/presale_team');
            const data = await response.json();
            setPresaleTeam(data);
          } catch (error) {
            // console.error('Error fetching data: Equipo Preventa', error);
          }
        };
    
        fetchData();
    }, []); 

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(URL+'/survey_type');
            const data = await response.json();
            setSurveyType(data);
          } catch (error) {
            // console.error('Error fetching data: Equipo Preventa', error);
          }
        };
    
        fetchData();
    }, []); 

    /* useEffect(() => {
        setProduct(productos)
        setPresaleTeam(equipo_preventa)
        setSurveyType(tipo_de_encuesta)
    }, []);  */

    /* const url = `${URL}/contacto/`

    const fetchUrl = async () => {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(sendData),
        })
        const json = await response.json()
        if (json.error) {
            setSubmitStatus("error")
        } else {
            setSubmitStatus("send")
            navigate('/agradecimiento');
            reset(response)
        }
    }

    useEffect(() => {
        if (submitClick) {
            setSubmitStatus("working")
            fetchUrl()
            setSubmitClick(false)
        }
    }, [submitClick]) */

    const onSubmitHandler = async (data) => {
        try {
            setEnviarStatus("Enviando")
            const formData = new URLSearchParams(data).toString();
            const response = await fetch(URL+'/form/productOwnerForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formData
            });

            const responseData = await response.json();
            // console.log("ACA :", responseData)

            if (responseData.ok === true) {
                // console.log(responseData);
                // console.log('Form submitted successfully.');
                navigate('/agradecimiento');
            } else {
                // console.log('Form submision failed.');
            }
        } catch (error) {
            // console.error('Error submitting form:', error);
        }
        finally {
            setEnviarStatus("Enviar")
        }
    };

    return (
        <Styled>
            <div style={{ textAlign: 'center', padding: '2rem' }}>
                <h2 style={{ fontSize: '2rem', marginTop: '2rem', marginBottom: '1rem' }}>Encuesta de Satisfacción de Clientes</h2>
                <h4 style={{ color: 'grey', fontSize: '1.4rem', marginBottom: '1rem' }}>Formulario de envío de la Encuesta</h4>
            </div>
            <div className="ContactForm" style={{ display: 'flex', justifyContent: 'center' }}>
                <Card style={{ width: '100%', maxWidth: '800px', padding: '1rem', margin: '1rem' }}>
                    <CardContent>
                        <h2 className="title" style={{ fontSize: '1.5rem', marginBottom: '1.5rem', textAlign: 'center' }}>
                            <b>Datos del sender</b>
                        </h2>
                        <form
                            className="form"
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmitHandler)}
                        >
                            <div style={{ padding: '1rem' }}>
                                {camposDelSender.map(campo => (
                                    <div className="formControl" key={campo.id}>
                                        {campo.type === 'text' && (
                                            <StyledTextField
                                                label={campo.label}
                                                className="form-field"
                                                inputRef={register({ required: true })}
                                                name={campo.name}
                                                id={campo.name}
                                                inputProps={{ maxLength: 40 }}
                                                placeholder={campo.placeholder}
                                                size="small"
                                                variant="filled"
                                                fullWidth={true}
                                                helperText={errors[campo.name] ? "Campo obligatorio" : false}
                                                error={errors[campo.name] ? true : false}
                                            />
                                        )}
                                        {campo.type === 'email' && (
                                            <StyledTextField
                                                label={campo.label}
                                                className="form-field"
                                                inputRef={register({
                                                    required: true,
                                                    pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    },
                                                })}
                                                name={campo.name}
                                                id={campo.name}
                                                placeholder={campo.placeholder}
                                                size="small"
                                                inputProps={{ maxLength: 50 }}
                                                variant="filled"
                                                fullWidth={true}
                                                type="email"
                                                helperText={errors[campo.name] && "Ingrese un formato de correo válido"}
                                                error={errors[campo.name] ? true : false}
                                            />
                                        )}
                                        {campo.type === 'select' && (
                                            <StyledSelectField
                                                label={campo.label}
                                                name={campo.name}
                                                id={campo.name}
                                                inputRef={register(campo.name, { required: true })}
                                                variant="filled"
                                                fullWidth={true}
                                                displayEmpty
                                                defaultValue={0}
                                                onChange={(e) => setValue(campo.name, e.target.value, { shouldValidate: true })}
                                                error={errors[campo.name] ? true : false}
                                            >
                                            <MenuItem value="0" disabled>
                                                {campo.label}
                                            </MenuItem>
                                            {campo.name === 'id_producto' &&
                                                product &&
                                                product.data.map((item, index) => (
                                                    <MenuItem key={index} value={item.id_producto}>
                                                        {item.descripcion}
                                                    </MenuItem>
                                            ))}
                                            {campo.name === 'id_equipo_preventa' &&
                                                presaleTeam &&
                                                presaleTeam.data.map((item, index) => (
                                                    <MenuItem key={index} value={item.id_equipo_preventa}>
                                                        {item.descripcion}
                                                    </MenuItem>
                                            ))}
                                            {campo.name === 'id_template' &&
                                                surveyType &&
                                                surveyType.data.map((item, index) => (
                                                    <MenuItem key={index} value={item.id_template}>
                                                        {item.descripcion}
                                                    </MenuItem>
                                            ))}
                                            </StyledSelectField> 
                                        )}
                                        {campo.type === 'date' && (
                                            <StyledDateField
                                                label={campo.label}
                                                type="date"
                                                name={campo.name}
                                                id={campo.name}
                                                fullWidth={true}
                                                defaultValue={getFormattedToday()}
                                                error={errors[campo.name] ? true : false}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                            <h2 className="title" style={{marginTop: '30px', marginBottom: '30px'}}>
                                <b>Datos del encuestado</b>
                            </h2>
                            <div>
                                {camposDelEncuestado.map(campo => (
                                    <div className="formControl" key={campo.id}>
                                        {campo.type === 'text' && (
                                            <StyledTextField
                                                label={campo.label}
                                                className="form-field"
                                                inputRef={register({ required: true })}
                                                name={campo.name}
                                                id={campo.name}
                                                inputProps={{ maxLength: 40 }}
                                                placeholder={campo.placeholder}
                                                size="small"
                                                variant="filled"
                                                fullWidth={true}
                                                helperText={errors[campo.name] ? "Campo obligatorio" : false}
                                                error={errors[campo.name] ? true : false}
                                            />
                                        )}
                                        {campo.type === 'email' && (
                                            <StyledTextField
                                                label={campo.label}
                                                className="form-field"
                                                inputRef={register({
                                                    required: true,
                                                    pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    },
                                                })}
                                                name={campo.name}
                                                id={campo.name}
                                                placeholder={campo.placeholder}
                                                size="small"
                                                inputProps={{ maxLength: 50 }}
                                                variant="filled"
                                                fullWidth={true}
                                                type="email"
                                                helperText={errors[campo.name] && "Ingrese un formato de correo válido"}
                                                error={errors[campo.name] ? true : false}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth={true}
                                    className="submit"
                                    style={{ backgroundColor: 'white', color: '#269094', flex: '1 1 100px' }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth={true}
                                    className="submit"
                                    style={{ backgroundColor: '#269094', flex: '1 1 100px' }}
                                >
                                    {enviarStatus}
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </Styled>
    )
}

const Styled = styled.div`
 .title {
    width: 100%;
    padding: '0px 70px';
    color: #000000;
    font-family: Questrial;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin: 0;

    
    @media (max-width: 780px) {
        padding: 0px 10px;
    }
 }

 .linkAccess{
    margin: 0 auto;
    height: 67px;
    max-width: 500px;
    border-radius: 33.5px;
    background-color: #24CC65;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .icon {
        width: 70px;
        margin-left: 5px;
        svg {
            height: 40px;
            width: 71.11px;
        }
    }

    .desc {

        padding-right: 15px;

        p {
            color: #FFFFFF;
            font-family: "Century Gothic";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            margin: 0;
            text-decoration: none;

            a {
                color: white !important;
            }
        }
    }
 }

 .formControl{
     min-height: 65px;     

     .form-field:after {
        border-bottom-color: #f44336 !important;
    }
 }
 .submit{
    color: #FFFFFF;
    font-family: "Century Gothic";
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-top: 32px;
    height: 40px;
    background-color: #A5BE01;
 }

 .form {
    margin-bottom: 0;
 }
`

export default EncuestaFormulario;