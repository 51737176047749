const camposDelSender = [
    {
      id: 1,
      label: 'Nombre Product Owner/Service lead',
      name: 'product_owner',
      type: 'text',
      placeholder: 'Escribe aquí'
    },
    {
      id: 2,
      label: 'Email',
      name: 'email_po',
      type: 'email',
      placeholder: 'Escribe aquí'
    },
    {
      id: 3,
      label: 'Nombre Empresa',
      name: 'nombre_empresa',
      type: 'text',
      placeholder: 'Escribe aquí'
    },
    {
      id: 4,
      label: 'Nombre del Proyecto o Servicio',
      name: 'proyecto',
      type: 'text',
      placeholder: 'Escribe aquí'
    },
    {
      id: 5,
      label: 'Tipo de práctica',
      name: 'id_producto',
      type: 'select'
    },
    {
      id: 6,
      label: 'Equipo asignado',
      name: 'id_equipo_preventa',
      type: 'select'
    },
    {
      id: 7,
      label: 'Tipo de encuesta',
      name: 'id_template',
      type: 'select'
    },
    {
      id: 8,
      label: 'Selecciona fecha envío de encuesta',
      name: 'fecha_envio_encuesta',
      type: 'date',
      placeholder: 'Selecciona fecha envío de encuesta'
    }
];

const camposDelEncuestado = [
    {
      id: 1,
      label: 'Nombre del cliente/Área',
      name: 'nombre_cliente',
      type: 'text',
      placeholder: 'Escribe aquí'
    },
    {
      id: 2,
      label: 'Nombre del encuestado',
      name: 'nombre_encuestado',
      type: 'text',
      placeholder: 'Escribe aquí'
    },
    {
      id: 3,
      label: 'Cargo del encuestado',
      name: 'cargo_encuestado',
      type: 'text',
      placeholder: 'Escribe aquí'
    },
    {
      id: 4,
      label: 'Email del encuestado',
      name: 'mail_encuestado',
      type: 'email',
      placeholder: 'Escribe aquí'
    }
];

export { camposDelSender, camposDelEncuestado };